import Axios from '@/axios';

export default {
  listOrders: async (params) => {
    const response = await Axios.get(`/printer-service/v2/orders`, {
      params
    });

    return response.data.data;
  }
};
