<template>
  <div>
    <b-overlay spinner-small variant="transparent" blur="none" :show="submitting" class="d-inline-block">
      <b-form-checkbox
        v-model="mailed"
        button
        button-variant="outline-secondary"
        class="d-inline-block"
        :disabled="submitting"
        @change="setMailed"
      >
        <p class="mb-0" v-if="mailed">
          <span>
            <i class="fa-solid fa-check mr-2"></i>
          </span>
          Printed
        </p>
        <p class="mb-0" v-else>Mark as printed</p>
      </b-form-checkbox>
    </b-overlay>
  </div>
</template>

<script>
import OrderService from '@/lib/order-service-v2';

export default {
  name: 'OrderPrintedToggle',
  props: ['value', 'orderId'],
  data() {
    return {
      submitting: false,
      errorMessage: '',
      mailed: false
    };
  },
  mounted() {
    if (this.value !== null || undefined) {
      this.mailed = true;
    }
  },
  methods: {
    async setMailed(value) {
      this.submitting = true;

      try {
        await OrderService.updateOrder(this.orderId, { mailed: value });
        this.$emit('input', value);
      } catch (error) {
        this.$emit('error', error);

        this.$nextTick(() => {
          this.printed = this.value;
        });
      }
      this.submitting = false;
    }
  }
};
</script>
