<template>
  <b-container fluid class="mb-7">
    <b-row class="controls do-not-print">
      <b-col cols="4">
        <h1>Loading Orders for Printing</h1>
        <h3>
          {{ eventName }} <span v-if="addonName">&amp; {{ addonName }}</span>
        </h3>
        <h3>Total Orders: {{ maxLoadingValue }}</h3>
        <h3>Total Orders Displayed: {{ orders.length }}</h3>
      </b-col>
      <b-col>
        <h3>Filters</h3>
        <b-form-group aria-readonly="Filters">
          <b-form-checkbox v-model="emailMissing" name="emailMissing" class="mt-2" switch>
            Exclude orders with emails
          </b-form-checkbox>
          <b-form-checkbox v-model="mailed" name="mailed" class="mt-2" switch> Show printed </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col cols="2" align-self="end">
        <LoadingButton v-if="!loadingOrders && orders.length" variant="default" @click.native="allPrinted">
          Mark all printed
        </LoadingButton>
      </b-col>
    </b-row>
    <Alert v-if="errorMessage" variant="red" icon="exclamation" show>{{ errorMessage }}</Alert>
    <div v-if="loadingOrders">
      <b-progress class="mt-2" :max="maxLoadingValue" animated>
        <b-progress-bar :value="loadingValue" variant="success"></b-progress-bar>
      </b-progress>
    </div>
    <template v-else>
      <b-row v-for="order in orders" :key="order.id" class="divider-line">
        <b-col class="order-section">
          <b-row>
            <b-col>
              <img
                src="https://images.rafflebox.ca/RaffleboxLogos/wordmark-green-2x.png"
                alt="Rafflebox"
                style="width: 200px"
              />
              <br /><br />
              <div class="mb-3">Order #{{ formatUuid(order.id) }}</div>
              <div v-if="addonId === order.eventId" class="mb-3">Bonus Draw Raffle</div>
              <div>{{ order.name }}</div>
              <div class="secondaryName" v-if="order.secondaryName">Additional Name(s): {{ order.secondaryName }}</div>
              <div>{{ order.address }}</div>
              <div>{{ order.city }}, {{ order.province }}</div>
              <div>{{ order.postal }}</div>
            </b-col>
            <b-col>
              <img :src="imageUrl(order.eventId)" alt="" style="max-width: 500px; margin-bottom: 1rem" />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="email-header" v-if="addonId === order.eventId" v-html="addonEmailHeader"></div>
              <div class="email-header" v-else v-html="emailHeader"></div>
              <p>
                The draw {{ licenseNumber }} will take place on {{ formatDateTime(drawDate, { format: 'date' }) }} at
                {{ drawLocation }}. The winner will then be contacted by {{ organizationName }} to claim their prize!
                Your ticket numbers are below:
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <br />
              <div v-for="ticket in order.tickets" :key="ticket.ticketNumber" class="d-inline-block ticket-box">
                {{ ticket.series }}-{{ ticket.ticketNumber }}
              </div>
            </b-col>
          </b-row>
          <small>{{ `If you would like to run a fundraiser please visit ${url}` }}</small>
        </b-col>
        <b-col cols="2" class="do-not-print">
          <div class="mark-as-printed">
            <OrderPrintedToggle v-if="canPrintTickets" v-model="order.mailedAt" :orderId="order.id" />
          </div>
        </b-col>
      </b-row>
    </template>
    <!-- End of Orders loop -->
  </b-container>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import { parseISO } from 'date-fns/fp';
import config from '@/config';

import PrinterService from '../lib/printer-service-v2';
import EventService from '../lib/event-service-v2';
import OrderService from '../lib/order-service-v2';

import LoadingButton from '@/components/ui/LoadingButton';
import Alert from '@/components/ui/Alert';
import OrderPrintedToggle from '@/components/OrderPrintedToggle';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  components: {
    Alert,
    LoadingButton,
    OrderPrintedToggle
  },
  data() {
    return {
      organizationName: null,
      orders: [],
      orderIds: [],
      eventName: null,
      emailHeader: null,
      addonName: null,
      addonEmailHeader: null,
      logoUrl: null,
      addonLogoUrl: null,
      drawDate: null,
      drawLocation: null,
      licenseNumber: null,
      loadingValue: 1,
      maxLoadingValue: 0,
      loadingOrders: true,
      errorMessage: null,
      mailed: false,
      eventId: this.$route.query.eventId || null,
      addonId: null,
      orderId: this.$route.query.orderId || null,
      emailMissing: this.$route.query.emailMissing || null,
      printAll: true,
      canPrintTickets: false
    };
  },
  metaInfo: {
    title: 'Print tickets'
  },
  watch: {
    emailMissing: async function () {
      this.loadingOrders = true;
      await this.getOrders().then(() => (this.loadingOrders = false));
    },
    mailed: async function () {
      this.loadingOrders = true;
      await this.getOrders().then(() => (this.loadingOrders = false));
    }
  },
  async mounted() {
    try {
      await this.getEvents();
      await this.getOrders();
    } catch (error) {
      this.error = error;
    } finally {
      this.loadingOrders = false;
    }
    this.canPrintTickets = await unleashFeatureEnabled(UnleashKeys.PrintTickets);
  },
  computed: {
    url: () => config.RAFFLEBOX_URL
  },
  methods: {
    imageUrl(id) {
      return this.addonId === id ? this.addonLogoUrl : this.logoUrl;
    },
    async allPrinted() {
      this.orderIds = this.getAllOrderIds();

      try {
        this.loadingOrders = true;

        const body = {
          orderIds: this.orderIds,
          mailed: true
        };

        await OrderService.updateOrdersToMailed(body);
        await this.getOrders();
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.loadingOrders = false;
      }
    },
    getAllOrderIds() {
      return this.orders.map((item) => {
        return item.id;
      });
    },
    async getEvents() {
      if (this.eventId === null) {
        // This assumes there is an order Id in the URL
        const order = await OrderService.retrieveOrder(this.orderId);
        this.eventId = order.eventId;

        // If printing a single order, ensure that even if it has no email it still appears.
        if (!order.email) {
          this.emailMissing = true;
        }
      }

      try {
        const event = await EventService.retrieveEvent(this.eventId);

        this.eventName = event.name;
        if (event.childEvents.length) {
          this.addonId = event.childEvents[0].id;
          this.addonName = event.childEvents[0].name;
          this.addonLogoUrl = event.childEvents[0].logoUrl;
          this.addonEmailHeader = event.childEvents[0].emailTicketBody;
        }

        this.logoUrl = event.logoUrl;
        this.licenseNumber = event.licenseNumber;

        if (!event.drawDate) {
          throw new Error('Missing draw date');
        }

        this.drawDate = parseISO(event.drawDate);
        this.drawLocation = event.drawLocation;
        const sessionUser = await getAuth().sessionUser();
        this.organizationName = sessionUser.organizationName;
        this.emailHeader = event.emailTicketBody;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },
    async getOrders() {
      let hasMore = true;
      let page = 0;
      this.orders = [];

      do {
        const params = {
          eventId: this.eventId,
          orderId: this.orderId,
          mailed: this.mailed,
          page: page
        };

        if (this.emailMissing === true) {
          params.emailMissing = true;
        }

        const listOrdersResult = await PrinterService.listOrders(params);
        const orders = listOrdersResult.data;

        if (orders?.length > 0) {
          this.loadingValue = (page + 1) * orders.length;
          this.maxLoadingValue = listOrdersResult.total;

          this.orders = [...this.orders, ...orders];
        }

        hasMore = listOrdersResult.hasMore;
        page++;
      } while (hasMore);
    }
  }
};
</script>
<style scoped>
.container-fluid {
  font-size: 1.4rem;
  padding: 1.875rem 5rem 1.875rem 7rem;
  text-align: left;
}

.controls {
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid #ccc;
}

.divider-line {
  page-break-before: always;
  margin-bottom: 2rem;
  padding: 2rem 0;
  border-bottom: 2px solid #ddd;
}

.order-section {
  max-width: 1380px;
}

.ticket-box {
  margin: 1px;
  padding: 2px 3px;
  min-width: 100px;
  text-align: center;
  border: 1px solid #000000;
}

@media print {
  .do-not-print {
    display: none;
  }

  .divider-line {
    border: 0;
  }
}
</style>

<style>
/* unscoped to style the email header HTML returned from API */
.email-header {
  margin: 1rem 0;
}

.email-header p {
  font-size: 1rem;
  text-align: left !important; /* Overwrite inline styles */
  margin-bottom: 0;
}
</style>
